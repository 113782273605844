import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="new-product"
export default class extends Controller {
  static targets = [
    "form",
    "sku",
    "skuLabel",
    "category1",
    "category2",
    "category3"
  ]
  static values = {
    originalId: String,
    actionName: String
  }

  connect() {
    if (!this.category2Target.value) {
      this.category2Target.disabled = true
    }
    if (!this.category3Target.value) {
      this.category3Target.disabled = true
    }

    this.category1Target.addEventListener('change', (event) => {
      var product_category2_id = this.category2Target.value;
      var category_id = this.category1Target.value;

      fetch("/categories.json?category_id=" + category_id)
      .then(response => response.json())
      .then(data => {
        // Handle data here
        console.log(data);
        var categories = data;
	// Remove all child nodes
	while (this.category2Target.firstChild) {
	  this.category2Target.removeChild(this.category2Target.firstChild);
	}

        const emptyOption = document.createElement('option');
        emptyOption.value = '';
        emptyOption.text = '';
	this.category2Target.appendChild(emptyOption)

        if (categories.length == 0) {
          this.category2Target.disabled = true
        } else {
          for(var i = 0; i < categories.length; i++){
            const option = document.createElement('option');
            option.value = categories[i]["id"];
            option.text = categories[i]["name"];
	    this.category2Target.appendChild(option)
          }
          this.category2Target.disabled = false
          this.category2Target.value = product_category2_id; 
        }
      })
      .catch(error => {
        // Handle errors here
        console.error('AJAX Error: ' + status + error)
      });

      return true;
    })

    this.category2Target.addEventListener('change', (event) => {
      var product_category3_id = this.category3Target.value;
      var category_id = this.category2Target.value;

      fetch("/categories.json?category_id=" + category_id)
      .then(response => response.json())
      .then(data => {
        // Handle data here
        console.log(data);
        var categories = data;

	// Remove all child nodes
	while (this.category3Target.firstChild) {
	  this.category3Target.removeChild(this.category3Target.firstChild);
	}

        const emptyOption = document.createElement('option');
        emptyOption.value = '';
        emptyOption.text = '';
	this.category3Target.appendChild(emptyOption)

        if (categories.length == 0) {
          this.category3Target.disabled = true
        } else {
          for(var i = 0; i < categories.length; i++){
            const option = document.createElement('option');
            option.value = categories[i]["id"];
            option.text = categories[i]["name"];
	    this.category3Target.appendChild(option)
          }
          this.category3Target.disabled = false
          this.category3Target.value = product_category3_id; 
        }
      })
      .catch(error => {
        // Handle errors here
        console.error('AJAX Error: ' + status + error)
      });

      return true;
    })

    var product_category2_id = this.category2Target.value;
    var product_category3_id = this.category3Target.value;

    let event1 = new Event('change');
    this.category1Target.dispatchEvent(event1);
    let event2 = new Event('change');
    this.category2Target.dispatchEvent(event2);

    if (this.actionNameValue === 'new') {
      if (this.skuTarget.value && this.skuTarget.value.trim()) {
        this.fetchProduct()
	const length = this.skuTarget.value.length;
        this.skuTarget.setSelectionRange(length, length);
	this.skuTarget.focus()
      }
    }

    this.skuTarget.addEventListener('input', (event) => {
      this.fetchProduct();
    })
  }

  fetchProduct() {
    fetch('/products/' + this.skuTarget.value + '.json', { method: 'HEAD' })
    .then(response => {
      const className = 'field_with_errors'

      if (response.ok) {
        console.log('Resource exists');

        const wrapper = document.createElement('div');
        wrapper.className = className;

	if (this.skuTarget.parentNode && !this.skuTarget.parentNode.classList.contains(className)){
          // Get the parent of the element
          const parent = this.skuTarget.parentNode;
	  const wrapperClone = wrapper.cloneNode(true);

          // Insert the wrapper before the element
          parent.insertBefore(wrapperClone, this.skuTarget);
          // Move the element inside the wrapper
          wrapperClone.appendChild(this.skuTarget);

	  this.skuTarget.focus()
        }
	if (this.skuLabelTarget.parentNode && !this.skuLabelTarget.parentNode.classList.contains(className)){
          // Get the parent of the element
          const parent = this.skuLabelTarget.parentNode;
	  const wrapperClone = wrapper.cloneNode(true);

          // Insert the wrapper before the element
          parent.insertBefore(wrapperClone, this.skuLabelTarget);
          // Move the element inside the wrapper
          wrapperClone.appendChild(this.skuLabelTarget);
	
          this.skuLabelTarget.textContent = 'Nummer vorhanden'
        }
      } else {
        console.log('Resource does not exist');

	if (this.skuTarget.parentNode && this.skuTarget.parentNode.classList.contains(className)){
	  const parent = this.skuTarget.parentNode;

          while (parent.firstChild) {
            parent.parentNode.insertBefore(parent.firstChild, parent);
          }

          parent.parentNode.removeChild(parent);

	  this.skuTarget.focus()
	}
	if (this.skuLabelTarget.parentNode && this.skuLabelTarget.parentNode.classList.contains(className)){
	  const parent = this.skuLabelTarget.parentNode;

          while (parent.firstChild) {
            parent.parentNode.insertBefore(parent.firstChild, parent);
          }

          parent.parentNode.removeChild(parent);

          this.skuLabelTarget.textContent = 'Nummer'
	}
      }
    })
    .catch(error => {
      console.error('Error checking resource:', error);
    });
  }
}
